import React,{useState, useEffect} from 'react'
import { StaticImage } from 'gatsby-plugin-image';


  const Securefeaof = () => {

    const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);



    return (
      <section className="banner secureof pt-100">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Add-on Modules</span> of our Crypto Payment Gateway Solutions</h2>
          <p className="text-center">As per the crypto market trend, we enable the below-mentioned latest add-on modules to make your payment gateway outstanding and user-friendly.</p>
          <div className="row table-content mw1030 m-auto">
          {isDesktop && (
            <div className="col-lg-9 col-md-12 col-sm-12 col-12 left-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/cryptopayment/add-on-module-crypto-payment.webp"
                  alt="Add-on Modules of our White Label Crypto payment gateway"
                  width={550}
                />
            </div>
          )}
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <ul>
                <li><p>IPN History</p></li>
                <li><p>API Key Generation</p></li>
                <li><p>Multi-lingual support</p></li>
                <li><p>Admin profit management</p></li>
                <li><p>Automatic settlement</p></li>
                <li><p>Data Analytics</p></li>
                <li><p>Buy crypto with a Debit card</p></li>
                <li><p>Swap</p></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Securefeaof