import React,{useState, useEffect} from 'react'
import { StaticImage } from 'gatsby-plugin-image'


  const Whychoose = () => {

    const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

    return (
      <section className='whitelabcrypto whychoose pt-100'>
        <div className="container">
          <div className="text-center">
            <h2 className="main-heading"><span className='bluecolor'>What Makes Coinsclone</span> As The Best Cryptocurrency Payment Gateway Development Company?</h2>
            <p className="pharagraph text-center">
            At our company, we understand the complexities and requirements of cryptocurrency payment gateway development. Our services are designed to provide seamless and efficient payment processing solutions for businesses of all sizes. Whether you need a multi-coin crypto-payment gateway or a white-label cryptocurrency payment gateway development, we have you covered.
            </p>
            <p className="pharagraph text-center">
            Our pool of skilled crypto payment gateway developers works consistently in order to deliver an impeccable product outcome with the utmost perfection. If you want to revolutionize the entire crypto ecosystem effectively with your outstanding business plan, then the Coinsclone team is here for you. We assure to turn your dream crypto business into reality within your business budget.
            </p>
            <div className="row table-content mw1030 m-auto">
            {isDesktop && (
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/cryptopayment/why-choose-crypto-payment.png"
                  alt="Why Choose Crypto Payment Gateway"
                  width={364}
                />
            </div>
            )}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <b className='d-block mb-3 text-left'>Our team of experts is readily available to serve you with exemplary turn-key solutions!</b>
              <ul className='mb-0'>
              <li><p>Quality assurance</p></li>
              <li><p>24/7 customer & technical assistance</p></li>
              <li><p>On-time product delivery</p></li>
              <li><p>70+ proficient blockchain developers</p></li>
              <li><p>Priors Client Satisfaction</p></li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      </section>
    )
}

export default Whychoose